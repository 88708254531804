import { get, once } from 'lodash';
import { createPerformanceTracker, resetMetrics } from '@wix/communities-blog-client-common';
import {
  createLogger,
  initializeNavigationHandlerForEditor,
  doRouting,
  createControllerId,
  onLocationChange,
  isFrameless,
  getSectionPathname,
} from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { getSectionUrl } from '../services/get-section-url';
import { BLOG_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createReduxStore } from './create-redux-store';
import reducers from '../reducers';
import { createRouter } from './create-router';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { FEED_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { getPathname } from '../../common/store/location/get-pathname';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { isInCategory } from '../../common/services/detect-route';
import { fetchSiteProperties } from '../../common/store/site-properties/site-properties-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';

const isProduction = process.env.NODE_ENV === 'production';

const getSectionPathnameAfterLogin = async (wixCodeApi, appParams) =>
  getPathname(
    wixCodeApi.location,
    await getSectionUrl({
      wixCodeApi,
      instanceId: appParams.instanceId,
      sectionId: BLOG_SECTION_ID,
    }),
  );

export const createFeedPageController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
  context,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { sentry, captureToSentry, fedopsLogger } = createMonitoring({
    origin: `app-worker-${FEED_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    wixCodeApi,
    type,
  });

  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const language = getCurrentSiteLanguage(wixCodeApi);

  const bundleName = 'blog';

  const log = createLogger(isDebug, isProduction);
  const perf = createPerformanceTracker(bundleName, { isDebug, isSSR });

  log('createFeedPageController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  let store;

  const pageReady = async () => {
    try {
      const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
      log('createFeedPageController.pageReady -> start');

      const sectionUrl = await getSectionUrl({
        wixCodeApi,
        instanceId: appParams.instanceId,
        sectionId: BLOG_SECTION_ID,
      });

      simulateControllerError(wixCodeApi, 'feed-page.pageReady');

      // eslint-disable-next-line
      let router;
      const getRouter = () => router;

      initLazyActions({ staticsBaseUrl: appParams.baseUrls.staticsBaseUrl, isPreview, isEditor, isSSR });

      let marker = perf.trackStart('createReduxStore', pageReadyMarker);
      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        reducers,
        fedopsLogger,
        getRouter,
        platformAPIs,
        isSSR,
        sentry,
        isEditor,
        isPreview,
        language,
        bundleName,
      });
      perf.trackEnd(marker);

      if (isEditor || isPreview) {
        await store.dispatch(fetchExperiments(context.experimentsPromise));
      }

      marker = perf.trackStart('initializeActions', pageReadyMarker);
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
      });
      const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });
      perf.trackEnd(marker);

      marker = perf.trackStart('createRouter', pageReadyMarker);
      router = createRouter(store, config, wixCodeApi, compId);
      perf.trackEnd(marker);

      const pathname = getSectionPathname({ store, wixCodeApi, sectionUrl });
      const matchedRoute = await router.matchPath(pathname);
      const route = get(matchedRoute, 'route');
      const preFetch = () =>
        Promise.all([
          router.preFetch(pathname),
          isInCategory(route) ? store.dispatch(fetchSiteProperties()) : Promise.resolve(),
        ]);

      await perf.trackPromise(
        'initializeStoreBaseData',
        () =>
          initializeStoreBaseData({
            wixCodeApi,
            store,
            language,
            platformAPIs,
            config,
            context,
            bundleName,
            fedopsAppName: 'communities-blog-app',
            translationsName: 'main',
            preFetch,
            appParams,
          }),
        pageReadyMarker,
      );

      onLocationChange(wixCodeApi, ({ path }) => {
        log('navigated to new path', path);
        resetMetrics(bundleName);
        doRouting({
          store,
          router,
          pathname: isFrameless({ store, wixCodeApi })
            ? getSectionPathname({ store, wixCodeApi, sectionUrl })
            : '/' + path.join('/'),
          isInitialLoad: false,
        });
      });

      await perf.trackPromise(
        'doRouting',
        () =>
          doRouting({
            store,
            router,
            pathname: getSectionPathname({ store, wixCodeApi, sectionUrl }),
            isInitialLoad: true,
          }),
        pageReadyMarker,
      );

      log('createFeedPageController.pageReady -> done');
      const state = store.getState();

      // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
      router.onMatch((match) => store.dispatch(setRouterMatch(match)));

      const stateVersions = getInitialStateVersions(state);
      const controllerId = createControllerId();

      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
        controllerId,
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToEditModeChange(store);
        listenToSettingsChange(store);
        initializeNavigationHandlerForEditor({ store, router });
      }

      refreshDataOnLogin({
        wixCodeApi,
        store,
        router,
        getPathname: () => getSectionPathnameAfterLogin(wixCodeApi, appParams),
        doRouting,
      });
      subscribeToChange(store, stateVersions, setProps, controllerId);
      perf.trackEnd(pageReadyMarker);
    } catch (error) {
      handleError({ controller: FEED_WIDGET_NAME, store, setProps, appParams, captureToSentry, isDebug, isSSR })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams } }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
    },
  }).catch(console.error);
};
